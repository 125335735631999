<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-21">
        <div class="col-md-12">
          <div>
            <h4 class="mb-5 font-weight-bold text-dark">
              All Vouchers <br />
              <small>Click add to create a new voucher or edit below.</small>
            </h4>
            <b-button  v-if=" $hasPermission('manage business settings ') "  class="bg-primary text-light font-poppins"  v-b-modal.modal-1 @click="editData({})"
              >Add Vouchers</b-button
            >
            <div class="table-responsive mt-5">
              <table
                class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-borderless
                "
              >
                <thead>
                  <tr class="text-left">
                    <th style="min-width: 120px">Code</th>
                    <th style="min-width: 120px">Discount</th>
                    <th style="min-width: 120px">Applicable For</th>
                    <th style="min-width: 100px">Percentage</th>
                    <th style="min-width: 100px">Products</th>
                    <th style="min-width: 100px">Toppings Discount</th>
                    <th style="min-width: 100px">Properties Discount</th>
                    <th style="min-width: 100px">Amount off</th>
                    <th style="min-width: 100px">Type</th>
                    <th style="min-width: 100px">Date</th>
                    <th style="min-width: 100px">Customer Counts</th>
                    <th style="min-width: 100px">Minimum Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in vouchers">
                    <tr v-bind:key="i">
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.code
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.discount
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.aplicablefor
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.percentage
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.free_products
                        }}</span>
                      </td>
                      <td>
                        <b-form-checkbox v-model="item.discount_product_toppings == 1" name="flavour-2a" ></b-form-checkbox>
                      </td>
                      <td>
                        <b-form-checkbox v-model="item.discount_product_properties == 1" name="flavour-2a" ></b-form-checkbox>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{$store.state.auth.currency}}{{
                            item.amountoff
                          }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.type
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-sm">{{
                          item.date
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.count
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          item.minimum
                        }}</span>
                      </td>
                      <td>
                        <div style="display: flex">
                          <a
                           v-if=" $hasPermission('manage business settings') "
                            v-b-modal.modal-1
                            @click="editData(item)"
                            class="btn btn-light-primary font-size-xl"
                            ><i class="flaticon2-pen"></i
                          ></a>
                          &nbsp;&nbsp;
                          <a
                           v-if=" $hasPermission('manage business settings') "
                            @click="deleteData(item.id)"
                            class="btn btn-light-danger font-size-xl"
                            ><i class="flaticon-delete"></i
                          ></a>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <b-modal id="modal-1" ref="voucher-model" title="Voucher Details" hide-footer>
              <AnnouncementForm
                @update="getAnnouncements"
                :record="data"
              ></AnnouncementForm>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ANNOUNCEMENTS , DELETE_VOUCHER } from "@/core/services/store/config.module";
import AnnouncementForm from "@/view/pages/business/form-components/AnnouncementForm";
import Swal from "sweetalert2";
export default {
  name: "Announcements",
  components: {
    AnnouncementForm,
  },
  data() {
    return {
      vouchers: [],
      data: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Announcements" },
      { title: "All Vouchers" },
    ]);

    this.getAnnouncements();
  },
  methods: {
    editData(item)
    {
      this.data = item
    },
    hideModal() {
      this.$refs["voucher-model"].hide();
    },
    getAnnouncements() {
      this.hideModal()
      this.vouchers = [];
      this.$store
        .dispatch(ANNOUNCEMENTS)
        .then((resp) => {
          this.vouchers = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteData(id) {
      alert(id)
      this.$store
        .dispatch(DELETE_VOUCHER, {
          id : id
        })
        .then((resp) => {
          Swal.fire("Confirmation", 'Voucher Deleted Successfully', "success");
          this.getAnnouncements();
        })
        .catch((error) => {
          Swal.fire("Exception", 'Something Went Wrong', "error");
          console.log(error);
        });
    },
  },
};
</script>
